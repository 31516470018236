
:root {
  //Colors
  --color-primary: #1C1C1E;
  --color-bg: #2C2C2E;
  --color-action: #F5C71A;
  --color-action-secondary: #F5C71A;
  --color-inactive: #F5C71A;
  --color-third: #3A3A3C;
  --color-text-dark: #000000;
  --color-text: #FFF;
  --color-cancel: #F44;
  --color-cancel-inactive: #FF2424;
  --color-warning: #E7E7E7;
  --color-text-light: rgba(255, 255, 255, 0.1);


  --datepicker-height: 170px;
}