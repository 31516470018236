*,
*::before,
*::after {
	box-sizing: border-box;
}

ul,
ol {
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

ul[class],
ol[class] {
	list-style: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img {
	max-width: 100%;
	display: block;
	outline: none;
	border: none;
}

a {
	color: inherit;
	text-decoration: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

// @media (prefers-reduced-motion: reduce) {
// 	* {
// 		animation-duration: 0.01ms !important;
// 		animation-iteration-count: 1 !important;
// 		transition-duration: 0.01ms !important;
// 		scroll-behavior: auto !important;
// 	}
// }

button,
fieldset {
	background: none;
	outline: none;
	border: none;
	padding: 0;
}

input,
textarea {
	border: 0 solid transparent;

	&:focus,
	&:hover {
		outline: none;
	}
}
