* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--color-primary);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;

  main {
    flex: 1 1 auto;
    width: 100%;
    min-height: 100vh;
  }
}

.root {
  position: absolute;
  overflow: hidden;
  background: var(--color-text-light);
  margin: 0;
  padding: 0;
  pointer-events: none;

}

input {
  border: 0 solid transparent;

  &:focus,
  &:hover {
    outline: none;
  }
}

button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

@font-face {
  font-family: "Integral CF";
  src: local("AirbnbCerealLight"),
  url("../fonts/Fontspring-DEMO-integralcf-bold.otf") format("truetype");
}

.transition-opacity-out {
  transition: opacity 0.3s ease-out 0s, transform 0.3s ease-out 0s, background-color 0.3s ease-out 0s;
}

.zindexEnter {
  z-index: 1;
}

.zindexLeave {
  z-index: -1
}

.background-enter {
  background-color: var(--color-primary);
}

.background-leave {
  background-color: transparent;
}

.transition-opacity-in {
  transition: opacity 0.2s ease-out 0s, transform 0.2s ease-out 0s, background-color 0.3s ease-out 0s;
}

.block {
  display: block !important;
}

.transition-filter-in {
  transition: filter, opacity 0.2s ease-in 0s;
}

.transition-opacity-in-slow {
  transition: opacity 0.3s ease-in 0s;
}

.transition-opacity-out-slow {
  transition: opacity 0.4s ease-in 0s;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.scale-95 {
  transform: scale(0.90);
}

.scale-100 {
  transform: scale(1);
}

.overlap {

}

.blur {
  filter: blur(7px);
}